import { IElement } from "@faro-lotv/ielement-types";
import {
  IElementsFetcher,
  addIElements,
  addToLoadingRoots,
  removeFromLoadingRoots,
  setRootId,
} from "@faro-lotv/project-source";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "@store/store-helper";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
/**
 * Fetch some of the IElements of the project
 * Optionally set an element as the root of the tree for loading indication
 *
 * This is wrapper for the `fetchProjectIElements` async thunk implemented by the lotv team.
 */
export const fetchProjectIElements = createAsyncThunk<
  IElement[],
  IElementsFetcher,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "ielements/fetchProjectIElements",
  async ({ fetcher, loadingIds }, { dispatch }) => {
    loadingIds?.map((loadingId) => dispatch(addToLoadingRoots(loadingId)));

    let elements: IElement[] = [];
    try {
      elements = await fetcher().finally(() => {
        loadingIds?.map((loadingId) =>
          dispatch(removeFromLoadingRoots(loadingId))
        );
      });
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }

    if (!elements.length) {
      return elements;
    }

    const root = elements.find((el) => el.id === elements[0].rootId);
    dispatch(addIElements(elements));
    if (root) {
      dispatch(setRootId(root.id));
    }

    return elements;
  }
);