import { Box } from "@mui/material";
import { FARO_CARD_ACTIONS } from "@components/common/faro-card/faro-card";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { MarkupActionSphereViewer } from "@pages/project-details/project-markups/markup-action-sphere-viewer";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { MarkupActionMoreOptions } from "@pages/project-details/project-markups/markup-action-more-options";

/** Markup actions buttons displayed in a markups table */
export function MarkupActions({ markup }: BaseMarkupProps): JSX.Element {
  return (
    <Box
      component="div"
      className={FARO_CARD_ACTIONS}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <MarkupActionSphereViewer markup={markup} />
     
      <SphereActionDivider />

      <MarkupActionMoreOptions markup={markup} />
    </Box>
  );
}